import {LOCAL_STORE_LABEL, PAGE_NAMES} from '@/api/define'
import i18n from '@/language/i18n'
import router from '@/router'
// Import {useCookies} from '@vueuse/integrations/useCookies'
import {defineStore} from 'pinia'
import Methods from '../api/methods'
import useLoading from './useLoading'
import useSetting from './useSetting'

const loadingStore = useLoading()
// Const cookies = useCookies()

const useUpdateMember = defineStore('useUpdateMember', {
  state : () => {
    return {
      locale          : 'ja',
      mode            : 'ChangeInfo',
      memberId        : null,
      memberRequestNo : null,
      registerData    : {
        skipBidConfirm  : null,
        address         : null,
        shipAddress     : null,
        currentPassword : null,
        password        : null,
      },
      filesName       : [],
      constants       : [],
      editFlag        : true,
      completedFlag   : false,
      confirmEmail    : null,
      confirmPassword : null,
      errors          : {},

      // Rule check button clicked
      ruleCheckBtnClicked         : false,
      // Widthdraw member dialog
      withdrawMemberComfirmDialog : false
    }
  },
  getters : {
  },
  actions : {
    reset() {
      this.memberId = null
      this.memberRequestNo = null
      this.registerData = {
        skipBidConfirm  : null,
        address         : null,
        shipAddress     : null,
        currentPassword : null,
        password        : null,
      }
      this.filesName = []
      this.constants = []
      this.editFlag = true
      this.completedFlag = false
      this.confirmEmail = null
      this.confirmPassword = null
      this.errors = {}
      // Rule check button clicked
      this.ruleCheckBtnClicked = false
      // Widthdraw member dialog
      this.withdrawMemberComfirmDialog = false
    },
    request(params) {
      return Methods.apiExcute('private/change-member-info', this.locale, params)
    },
    withdrawMember(cookies, $router) {
      loadingStore.setLoading(true)
      Methods.apiExcute('private/withdraw-member', this.locale).then(data => {
        loadingStore.setLoading(false)
        cookies.remove(LOCAL_STORE_LABEL.SESSION_TOKEN)
        $router.push({name : PAGE_NAMES.LOGIN_PAGE_NAME})
      })
        .catch(error => {
          loadingStore.setLoading(false)
          this.errors = Methods.parseHtmlResponseError($router, error)
        })
    },
    extraValidate() {
      if (this.registerData.password) {
        if (!this.confirmPassword) {
          this.errors.confirmPassword = i18n.t('MEMBER_INFO_EMPTY_MESSAGE')
        } else if (this.registerData.password !== this.confirmPassword) {
          this.errors.confirmPassword = i18n.t('PASSWORD_CONFIRM_ERROR_MESSAGE')
        }
        if (!this.registerData.currentPassword) {
          this.errors.currentPassword = i18n.t('CURRENT_PASSWORD_ERROR_MESSAGE')
        }
      }
    },
    getConstants(cookies, $router) {
      loadingStore.setLoading(true)

      Methods.apiExcute('private/get-change-info-constants', this.locale).then(data => {
        loadingStore.setLoading(false)
        this.constants = data.constants

        // 退会した、データが見つからない場合はログインページに戻る
        if (data.memberId) {
          this.memberId = data.memberId
          this.memberRequestNo = data.memberRequestNo
          if (data.member) {
            this.registerData = Object.assign(
              {},
              this.registerData,
              data.member,
              {
                skipBidConfirm : data.member.skipBidConfirm === 1
              }
            )
          }
          this.filesName = data.member.files
            ? [].concat(data.member.files).map(x => {
              return {key : x}
            })
            : []
          this.files = data.member.files ? [].concat(data.member.files) : []
        } else {
          cookies.remove(LOCAL_STORE_LABEL.SESSION_TOKEN)
          $router.push({name : PAGE_NAMES.LOGIN_PAGE_NAME})
        }
      })
        .catch(error => {
          loadingStore.setLoading(false)
          Methods.parseHtmlResponseError($router, error)
        })
    },
    closeWithdrawDialog() {
      this.withdrawMemberComfirmDialog = false
    },
    searchPostCode() {
      console.log('searchPostCode')
      if (this.registerData.postCode) {
        this.registerData.postCode = this.registerData.postCode.trim()
      }
      const params = {
        postCode : this.registerData.postCode
      }
      loadingStore.setLoading(true)
      Methods.apiExcute('search-post-code', this.locale, params).then(data => {
        loadingStore.setLoading(false)
        this.registerData.address = (data.address1 || '') + (data.address2 || '') + (data.address3 || '')
      })
        .catch(error => {
          loadingStore.setLoading(false)
          this.errors = Methods.parseHtmlResponseError(router, error)
        })
    },
    searchShipPostCode() {
      console.log('searchShipPostCode')
      if (this.registerData.shipPostCode) {
        this.registerData.shipPostCode = this.registerData.shipPostCode.trim()
      }
      const params = {
        postCode : this.registerData.shipPostCode
      }
      loadingStore.setLoading(true)
      Methods.apiExcute('search-post-code', this.locale, params).then(data => {
        loadingStore.setLoading(false)
        this.registerData.shipAddress = (data.address1 || '') + (data.address2 || '') + (data.address3 || '')
      })
        .catch(error => {
          loadingStore.setLoading(false)
          const errs = Methods.parseHtmlResponseError(router, error)
          this.errors = {shipPostCode : errs.postCode}
        })
    },
    validate($refs) {
      console.log('validate')
      if (this.ruleCheckBtnClicked === false) {
        return
      }

      // Trim
      Object.keys(this.registerData).forEach(x => {
        if (x !== 'password' && x !== 'currentPassword' && x !== 'email' && x !== 'skipBidConfirm' && this.registerData[x]) {
          this.registerData[x] = this.registerData[x].trim()
        }
      })

      const params = {
        memberRequestNo : this.memberRequestNo || null,
        registerData    : Object.assign({}, this.registerData, {
          skipBidConfirm : this.registerData.skipBidConfirm ? 1 : 0
        }),
        validateFlag    : true
      }
      this.errors = {}
      this.checkInputFormat()
      loadingStore.setLoading(true)

      console.log('params = ', params)
      this.request(params)
        .then(() => {
          if (Object.keys(this.errors).length === 0) {
            loadingStore.setLoading(false)
            this.editFlag = false
            window.scrollTo({
              top      : 0,
              left     : 0,
              behavior : 'smooth'
            })
          } else {
            loadingStore.setLoading(false)
          }
        })
        .catch(error => {
          console.log('error', error)
          loadingStore.setLoading(false)
          // 表示順のため
          let default_errs = {
            nickname         : null,
            nickname_dup     : null,
            name             : null,
            furigana         : null,
            birthday         : null,
            postCode         : null,
            address          : null,
            shipPostCode     : null,
            shipAddress      : null,
            tel              : null,
            email            : null,
            email_dup        : null,
            confirmEmail     : null,
            password         : null,
            confirmPassword  : null,
            skipBidConfirm   : null,
          }
          default_errs = Object.assign(default_errs, this.errors, Methods.parseHtmlResponseError(router, error))
          const tmp_errs = {}
          Object.keys(default_errs).map(x => {
            if (default_errs[x]) {
              tmp_errs[x] = default_errs[x]
            }
          })
          this.errors = Object.assign({}, tmp_errs)

          // Move pointer to first error item
          this.moveToError($refs)
        })
    },
    registerMemberRequest() {
      const params = {
        memberRequestNo : this.memberRequestNo || null,
        registerData    : Object.assign({}, this.registerData, {
          skipBidConfirm : this.registerData.skipBidConfirm ? 1 : 0
        })
      }
      loadingStore.setLoading(true)
      this.request(params).then(data => {
        // Update setting to store
        const settingStore = useSetting()
        settingStore.setSkipBidConfirm(this.registerData.skipBidConfirm)

        loadingStore.setLoading(false)
        this.completedFlag = true
        window.scrollTo({
          top      : 0,
          left     : 0,
          behavior : 'smooth'
        })
      })
        .catch(error => {
          loadingStore.setLoading(false)
          this.errors = Object.assign(Methods.parseHtmlResponseError(router, error), this.errors)
        })
    },
    checkInputFormat() {
      console.log('checkInputFormat')
      const tags = [
        'nickname',
        'name',
        'furigana',
      ]

      const pattern = new RegExp('^[0-9a-zA-Z -/:-@\[-~]+$')
      const jpPattern = new RegExp('^([^\x01-\x7E]|[0-9a-zA-Z -/:-@\[-~])+$')
      this.errors = {}
      for (const tag of tags) {
        if (this.registerData[tag]) {
          if (!jpPattern.test(this.registerData[tag])) {
            this.errors[tag] = i18n.t('MEMBER_REQUEST_TEXT_FORMAT_ERROR')
          }
        }
      }
      this.extraValidate()
    },
    isJapanView() {
      return true
    },
    ruleCheckClick() {
      // 更新ボタンの活性・非活性
      if (document.getElementById('rule-chk').checked === false) {
        console.log('uncheck')
        this.ruleCheckBtnClicked = false
      } else {
        console.log('checked')
        this.ruleCheckBtnClicked = true
      }
    },
    moveToError($refs) {
      const keys = Object.keys(this.errors)
      if (keys.length > 0) {
        let refkey = keys[0]
        if (keys[0] === 'email_dup') {
          refkey = 'email'
        } else if (keys[0] === 'nickname_dup') {
          refkey = 'nickname'
        }
        if (this.errors[keys[0]] && $refs[refkey]) {
          if (refkey === 'birthday') $refs[refkey].scrollIntoView()
          else $refs[refkey].focus()
        }
      }
    }
  },
})

export default useUpdateMember
