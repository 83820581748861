<template>
<main id="main" class="comp">
  <div class="wrap1160">
    <p class="comp-msg" v-html="$t('MEMBER_REQUEST_CONFIRM_EXPLANATION_MESSAGE')"></p>
    <section id="entry-form" class="bgLGray">
      <form>
        <table class="tbl-entry">
          <tbody>
          <tr>
            <th v-html="$t('MEMBER_REQUEST_CONFIRM_NICKNAME_LABEL')"></th>
            <td>
              {{registerData.nickname}}
              <p v-if="errors && errors.nickname" class="err-txt">{{errors.nickname}}</p>
              <p v-if="errors && errors.nickname_dup" class="err-txt">{{errors.nickname_dup}}</p>
            </td>
          </tr>
          <tr>
            <th v-html="$t('MEMBER_REQUEST_CONFIRM_NAME_LABEL')"></th>
            <td>
              {{registerData.name}}
            </td>
          </tr>
          <tr>
            <th v-html="$t('MEMBER_REQUEST_CONFIRM_FURIGANA_LABEL')"></th>
            <td>
              {{registerData.furigana}}
            </td>
          </tr>
          <tr>
            <th v-html="$t('MEMBER_REQUEST_FORM_BIRTHDAY_LABEL')"></th>
            <td>
              {{ registerData.birthday | dateFormat }}
            </td>
          </tr>
          <tr>
            <th v-html="$t('MEMBER_REQUEST_CONFIRM_POST_CODE_LABEL')"></th>
            <td>
              {{registerData.postCode}}
            </td>
          </tr>
          <tr>
            <th v-html="$t('MEMBER_REQUEST_CONFIRM_ADDRESS_LABEL')"></th>
            <td>
              {{registerData.address}}
            </td>
          </tr>
          <tr>
            <th v-html="$t('MEMBER_REQUEST_CONFIRM_SHIP_POST_CODE_LABEL')"></th>
            <td>
              {{registerData.shipPostCode}}
            </td>
          </tr>
          <tr>
            <th v-html="$t('MEMBER_REQUEST_CONFIRM_SHIP_ADDRESS_LABEL')"></th>
            <td>
              {{registerData.shipAddress}}
            </td>
          </tr>
          <tr>
            <th>
              {{$t('MEMBER_REQUEST_FORM_TEL_LABEL')}}<br />
            </th>
            <td>
              {{registerData.tel || ''}}
            </td>
          </tr>
          <tr>
            <th v-html="$t('MEMBER_REQUEST_CONFIRM_EMAIL_LABEL')"></th>
            <td>
              {{registerData.email}}
              <p v-if="errors && errors.email" class="err-txt">{{errors.email}}</p>
              <p v-if="errors && errors.email_dup" class="err-txt">{{errors.email_dup}}</p>
            </td>
          </tr>
          <tr v-if="registerData.currentPassword">
            <th v-html="$t('MEMBER_REQUEST_CURRENT_PASSWORD_LABEL')"></th>
            <td>
              ********
            </td>
          </tr>
          <tr v-if="registerData.password">
            <th v-html="$t('MEMBER_REQUEST_NEW_PASSWORD_LABEL')"></th>
            <td>
              ********
            </td>
          </tr>
          <tr v-if="registerData.password">
            <th v-html="$t('MEMBER_REQUEST_CONFIRM_NEW_PASSWORD_LABEL')"></th>
            <td>
              ********
            </td>
          </tr>
          <tr>
            <th>
              {{$t('MEMBER_EDIT_SKIP_BID_CONFIRM_LABEL')}}
            </th>
            <td>{{
              registerData.skipBidConfirm
                ? $t('MEMBER_EDIT_SKIP_BID_CONFIRM_TXT')
                : $t('MEMBER_EDIT_DONT_SKIP_BID_CONFIRM_TXT')
            }}</td>
          </tr>
          <tr>
            <th>
              {{$t('MEMBER_REQUEST_CONFIRM_AGREE_LABEL') + $t('MEMBER_REQUEST_CONFIRM_AGREE_LABEL2')}}
            </th>
            <td v-html="$t('MEMBER_REQUEST_FORM_AGREE_CHECKBOX_LABEL')"></td>
          </tr>
        </tbody>
        </table>
        <div class="btn-form">
          <input type="button" class="btn-back" :value="$t('MEMBER_REQUEST_CONFIRM_BACK_BUTTON_LABEL')" @click="updateMemberStore.ruleCheckBtnClicked=false; updateMemberStore.editFlag=true">
          <input type="button" :value="$t('MEMBER_REQUEST_CONFIRM_SEND_BUTTON_LABEL')" @click="updateMemberStore.registerMemberRequest">
        </div>
      </form>
    </section>
  </div>
</main>
</template>

<script>
import CommonFilters from '@/common/commonFilters'
import useLoading from '@/stores/useLoading'
import useUpdateMember from '@/stores/useUpdateMember'
import {defineComponent} from '@vue/composition-api'
export default defineComponent({
  setup() {
    const updateMemberStore = useUpdateMember()
    const loadingStore = useLoading()
    return {
      loadingStore,
      updateMemberStore
    }
  },
  props : {
  },
  mixins : [CommonFilters],
  data() {
    return {
      errors : {}
    }
  },
  computed : {
    registerData() {
      return this.updateMemberStore.registerData
    },
  },
  methods : {
  }
})
</script>

<style scoped lang="scss" src="@/assets/scss/style.scss"></style>
