<template>
  <div>
    <Register v-if="editFlag"/>
    <Completion v-else-if="completedFlag"/>
    <Comfirm v-else />
  </div>
  </template>

<script>
import useLoading from '@/stores/useLoading'
import useUpdateMember from '@/stores/useUpdateMember'
import {defineComponent} from '@vue/composition-api'
import Comfirm from '../../components/ChangeInfo/Comfirm'
import Completion from '../../components/ChangeInfo/Completion'
import Register from '../../components/ChangeInfo/Register'

export default defineComponent({
  setup() {
    const loadingStore = useLoading()
    const updateMemberStore = useUpdateMember()
    return {
      loadingStore,
      updateMemberStore
    }
  },
  components : {
    Comfirm,
    Completion,
    Register
  },
  data() {
    return {

    }
  },
  methods : {

  },
  created() {
    this.updateMemberStore.reset()
    this.updateMemberStore.getConstants(this.$cookies, this.$router)
  },
  computed : {
    editFlag() {
      return this.updateMemberStore.editFlag
    },
    completedFlag() {
      return this.updateMemberStore.completedFlag
    },
  },
  watch : {
    '$i18n.locale'() {
      this.updateMemberStore.getConstants(this.$cookies, this.$router)
    },
    registerData(value) {
      if (Object.keys(value).length === 0) {
        this.updateMemberStore.getConstants(this.$cookies, this.$router)
        this.updateMemberStore.confirmPassword = null
      }
    }
  }
})
</script>
