<template>
  <div class="password-comp">
    <input
      :ref="refTxt"
      v-bind:class="iptClass"
      v-model="password"
      :placeholder="$t('PLACEHOLDER_PASSWORD')"
      required
      :type="isVisiblePassword ? 'text' : 'password'"
      v-on:keydown="onKeyPress"
      :autocomplete="autocomplete"
      @keydown="(val) => $emit('keydown', val)"
    >
    <span v-if="!isFromLocalStorage" @click="() => (isVisiblePassword = !isVisiblePassword)" class="show-password"><Icon :icon="isVisiblePassword ? 'akar-icons:eye-slashed' : 'akar-icons:eye'" style="vertical-align: middle;"/></span>
    <p class="err-txt">{{error}}</p>
  </div>
</template>

<script>
import {Icon} from '@iconify/vue2'
import {defineComponent, ref} from '@vue/composition-api'

export default defineComponent({
  props : {
    value : {
      type    : String,
      default : null
    },
    error : {
      type    : String,
      default : null
    },
    refTxt : {
      type    : String,
      default : null
    },
    autocomplete : {
      type    : String,
      default : null
    },
    inputClass : {
      type    : String,
      default : ''
    }
  },
  emits : ['update:value'],
  setup(props, {emit}) {
    const isFromLocalStorage = ref(false)
    const isVisiblePassword = ref(false)

    if (props.value) isFromLocalStorage.value = true

    const onKeyPress = event => {
      if (isFromLocalStorage.value) {
        isFromLocalStorage.value = false
        emit('update:value', null)
      }
    }

    return {
      isFromLocalStorage,
      isVisiblePassword,
      onKeyPress
    }
  },
  components : {
    Icon
  },
  data() {
    return {

    }
  },
  methods : {
  },
  computed : {
    password : {
      get() {
        return this.value
      },
      set(val) {
        if (this.isFromLocalStorage) this.isFromLocalStorage = false
        this.$emit('update:value', val)
      }
    },
    iptClass() {
      const cls = {'ime-dis' : true, 'err' : !!this.error}
      if (this.inputClass) cls[this.inputClass] = true
      return cls
    }
  }
})
</script>
<style scoped lang="scss">
  .password-comp {
    width: 100%;
  }
  .password-comp .iptW-M {
    width: 360px;
  }
</style>
<style scoped lang="scss" src="@/assets/scss/style.scss"></style>
