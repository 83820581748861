<template>
<main id="main">
  <div class="wrap1160">
    <section id="entry-form" class="bgLGray">
      <form>
        <p class="entry-form-info" v-html="$t('MEMBER_REQUEST_FORM_ENTRY_FORM_INFO_MESSAGE')"></p>
        <table class="tbl-entry">
          <tbody>
          <tr>
            <th>
              {{$t('MEMBER_REQUEST_FORM_NICKNAME_LABEL')}}
              <em class="req">{{$t('MEMBER_REQUEST_FORM_REQUIRED_LABEL')}}</em>
            </th>
            <td>
              <input type="text" class="iptW-M" required v-model="registerData.nickname" v-bind:class="{'err': errors.nickname}" @keydown="errors.nickname=null" ref="nickname">
              <p v-if="errors.nickname" class="err-txt">{{errors.nickname}}</p>
              <p v-if="errors.nickname_dup" class="err-txt">{{errors.nickname_dup}}</p>
            </td>
          </tr>
          <tr>
            <th>
              {{$t('MEMBER_REQUEST_FORM_NAME_LABEL')}}
              <em class="req">{{$t('MEMBER_REQUEST_FORM_REQUIRED_LABEL')}}</em>
            </th>
            <td>
              <input type="text" class="iptW-M" required v-model="registerData.name" v-bind:class="{'err': errors.name}" @keydown="errors.name=null" ref="name">
              <p class="err-txt">{{errors.name}}</p>
            </td>
          </tr>
          <tr>
            <th>
              {{$t('MEMBER_REQUEST_FORM_FURIGANA_LABEL')}}
              <em class="req">{{$t('MEMBER_REQUEST_FORM_REQUIRED_LABEL')}}</em>
            </th>
            <td>
              <input type="text" class="iptW-M" required v-model="registerData.furigana" v-bind:class="{'err': errors.furigana}" @keydown="errors.furigana=null" ref="furigana">
              <p class="err-txt">{{errors.furigana}}</p>
            </td>
          </tr>
          <tr>
            <th>
              {{$t('MEMBER_REQUEST_FORM_BIRTHDAY_LABEL')}}
              <em class="req">{{$t('MEMBER_REQUEST_FORM_REQUIRED_LABEL')}}</em>
            </th>
            <td>
              <date-picker
                class="iptW-M"
                :modelValue="registerData.birthday"
                @update:modelValue="newValue => {errors.birthday = null; registerData.birthday = newValue}"
                v-bind:class="{'date-err': errors.birthday}"
                :errors="errors"
                refTxt="birthday"/>
            </td>
          </tr>
          <tr class="account-wrap">
            <th>
              {{$t('MEMBER_REQUEST_FORM_POST_CODE_LABEL')}}
              <em class="req">{{$t('MEMBER_REQUEST_FORM_REQUIRED_LABEL')}}</em>
            </th>
            <td class="u-pb0">
              <div class="ipt-wrap">
                <input
                  type="text"
                  class="ime-dis iptW-S"
                  v-model="registerData.postCode"
                  @keydown="errors.postCode=null"
                  required v-bind:class="{'err': errors.postCode}"
                  ref="postCode" />
                <input class="ipt-btn-gray zip-search" type="button" :value="$t('MEMBER_REQUEST_FORM_SEARCH_POST_CODE_BUTTON_LABEL')" @click="updateMemberStore.searchPostCode">
                <p class="err-txt">{{errors.postCode}}</p>
              </div>
            </td>
          </tr>
          <tr>
            <th>
              {{$t('MEMBER_REQUEST_FORM_ADDRESS_LABEL')}}
              <em class="req">{{$t('MEMBER_REQUEST_FORM_REQUIRED_LABEL')}}</em>
            </th>
            <td>
              <input type="text" class="iptW-M" :placeholder="$t('MEMBER_REQUEST_FORM_LOCATION_PLACEHOLDER')" required v-model="registerData.address" v-bind:class="{'err': errors.address}" @keydown="errors.address=null" ref="address">
              <p class="err-txt">{{errors.address}}</p>
            </td>
          </tr>
          <tr class="account-wrap">
            <th>
              {{$t('MEMBER_REQUEST_FORM_SHIP_POST_CODE_LABEL')}}
            </th>
            <td class="u-pb0">
              <div class="ipt-wrap">
                <input
                  type="text"
                  class="ime-dis iptW-S"
                  v-model="registerData.shipPostCode"
                  @keydown="errors.shipPostCode=null"
                  required v-bind:class="{'err': errors.shipPostCode}"
                  ref="shipPostCode" />
                <input class="ipt-btn-gray zip-search" type="button" :value="$t('MEMBER_REQUEST_FORM_SEARCH_POST_CODE_BUTTON_LABEL')" @click="updateMemberStore.searchShipPostCode">
                <p class="err-txt">{{errors.shipPostCode}}</p>
              </div>
            </td>
          </tr>
          <tr>
            <th>
              {{$t('MEMBER_REQUEST_FORM_SHIP_ADDRESS_LABEL')}}
            </th>
            <td>
              <input type="text" class="iptW-M" :placeholder="$t('MEMBER_REQUEST_FORM_LOCATION_PLACEHOLDER')" required v-model="registerData.shipAddress" v-bind:class="{'err': errors.shipAddress}" @keydown="errors.shipAddress=null" ref="shipAddress">
              <p class="err-txt">{{errors.shipAddress}}</p>
            </td>
          </tr>
          <tr>
            <th>
              {{$t('MEMBER_REQUEST_FORM_TEL_LABEL')}}<br />
              <em class="req">{{$t('MEMBER_REQUEST_FORM_REQUIRED_LABEL')}}</em>
            </th>
            <td>
              <input type="text" class="ime-dis iptW-M" :placeholder="$t('MEMBER_REQUEST_FORM_PHONE_NUMBER_PLACEHOLDER')" required v-model="registerData.tel" v-bind:class="{'err': errors.tel}" @keydown="errors.tel=null" ref="tel">
              <p class="err-txt">{{errors.tel}}</p>
            </td>
          </tr>

          <tr class="account-wrap">
            <th>
              {{$t('MEMBER_REQUEST_FORM_EMAIL_LABEL')}}<br />
              <em class="req">{{$t('MEMBER_REQUEST_FORM_REQUIRED_LABEL')}}</em>
            </th>
            <td>
              <input type="email" class="ime-dis iptW-M" required v-model="registerData.email" v-bind:class="{'err': errors.email}" @keydown="errors.email=null" ref="email">
              <p v-if="errors.email" class="err-txt">{{errors.email}}</p>
              <p v-if="errors.email_dup" class="err-txt">{{errors.email_dup}}</p>
            </td>
          </tr>

          <tr>
            <th v-html="$t('MEMBER_REQUEST_CURRENT_PASSWORD_LABEL')"></th>
            <td>
              <Password
                :value="registerData.currentPassword"
                @update:value="(val) => registerData.currentPassword=val"
                :error="errors.currentPassword"
                @keydown="errors.currentPassword=null"
                ref="currentPassword"
                autocomplete="new-password"
                inputClass="iptW-M"
              />
            </td>
          </tr>
          <tr>
            <th>
              {{$t('MEMBER_REQUEST_NEW_PASSWORD_LABEL')}}
            </th>
            <td>
              <Password
                :value="registerData.password"
                @update:value="(val) => registerData.password=val"
                :error="errors.password"
                @keydown="errors.password=null"
                ref="password"
                autocomplete="new-password"
                inputClass="iptW-M"
              />
            </td>
          </tr>
          <tr>
            <th v-html="$t('MEMBER_REQUEST_CONFIRM_NEW_PASSWORD_LABEL')"></th>
            <td>
              <Password
                :value="updateMemberStore.confirmPassword"
                @update:value="(val) => updateMemberStore.confirmPassword=val"
                :error="errors.confirmPassword"
                @keydown="errors.confirmPassword=null"
                ref="confirmPassword"
                autocomplete="new-password"
                inputClass="iptW-M"
              />
            </td>
          </tr>
          <tr class="account-wrap">
            <th style="vertical-align: baseline;">
              {{$t('MEMBER_EDIT_SKIP_BID_CONFIRM_LABEL')}}<br />
            </th>
            <td class="privacy-chk d-flex align-center">
              <v-checkbox
                ref="skipBidConfirm"
                v-bind:class="{'err': errors.skipBidConfirm}"
                v-model="registerData.skipBidConfirm"
                @change="errors.skipBidConfirm=null"
              >
                <template v-slot:label>
                  <span class="skip-txt">{{ $t('MEMBER_EDIT_SKIP_BID_CONFIRM_TXT') }}</span>
                </template>
              </v-checkbox>
            </td>
          </tr>
        </tbody>
        </table>
        <p class="form-privacy">
          {{$t('MEMBER_REQUEST_FORM_AGREE_CHECKBOX_CAUTION_MESSAGE1')}}
          <a class="link-text" @click="$routerGoNewWindow($define.PAGE_NAMES.PRIVACY_PAGE_NAME, null, {lang: $i18n.locale})">{{$t('MEMBER_REQUEST_FORM_AGREE_CHECKBOX_CAUTION_MESSAGE2')}}</a>
          {{$t('MEMBER_REQUEST_FORM_AGREE_CHECKBOX_CAUTION_MESSAGE3')}}
        </p>
        <table class="tbl-entry tbl-privacy">
          <tr>
            <th>
              {{$t('MEMBER_REQUEST_CONFIRM_AGREE_LABEL') + $t('MEMBER_REQUEST_CONFIRM_AGREE_LABEL2')}}
              <em class="req">{{$t('MEMBER_REQUEST_FORM_REQUIRED_LABEL')}}</em>
            </th>
            <td class="privacy-chk">
              <label for="rule-chk">
                <input type="checkbox" id="rule-chk" class="checkbox-input" @click="updateMemberStore.ruleCheckClick" required>
                <span class="checkbox-parts" v-html="$t('MEMBER_REQUEST_FORM_AGREE_CHECKBOX_LABEL')"></span>
              </label>
            </td>
          </tr>
        </table>
        <div class="btn-form">
          <input type="button" style="background-color:red" :value="$t('MEMBER_REQUEST_FORM_WITHDRAW_LABEL')" @click="updateMemberStore.withdrawMemberComfirmDialog=true">
          <input type="button" id="sbm-login" :value="$t('MEMBER_REQUEST_FORM_SUBMIT_LABEL')" :disabled="!updateMemberStore.ruleCheckBtnClicked" @click="updateMemberStore.validate($refs)">
        </div>
      </form>
    </section>
  </div>

  <WithdrawDialog
    :withdrawMemberComfirmDialog="updateMemberStore.withdrawMemberComfirmDialog"
    :closeWithdrawDialog="() => updateMemberStore.closeWithdrawDialog()"
    :withdrawMember="() => updateMemberStore.withdrawMember($cookies, $router)"
  />

</main>
</template>

<script>
import Password from '@/components/Login/Password'
import useUpdateMember from '@/stores/useUpdateMember'
import {Icon} from '@iconify/vue2'
import {defineComponent} from '@vue/composition-api'
import DatePicker from '../RegisterMember/DatePicker'
import WithdrawDialog from './withdrawDialog'

export default defineComponent({
  setup() {
    const updateMemberStore = useUpdateMember()
    return {
      updateMemberStore
    }
  },
  components : {
    Icon,
    WithdrawDialog,
    DatePicker,
    Password
  },
  props : {
  },
  data() {
    return {
    }
  },
  computed : {
    registerData() {
      return this.updateMemberStore.registerData
    },
    passwordType() {
      return this.updateMemberStore.passwordType
    },
    passwordConfirmType() {
      return this.updateMemberStore.passwordConfirmType
    },
    errors() {
      return this.updateMemberStore.errors
    }
  },
  methods : {
  }
})
</script>

<style scoped lang="scss">

  @media only screen and (min-width: 768px) {
    p.err-txt {
      position: absolute;
      top: 50%;
      left: 450px;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      width: 100%;
      max-width: 290px;
    }
  }
  @media only screen and (max-width: 767px) {
    p.err-txt {
        position: static;
        -webkit-transform: none;
        transform: none;
        max-width: 100%;
        margin-top: 5px;
    }
  }

  .with-int-no {
    width: 305px !important;
  }
  select.err {
    background-color: #fffafa !important;
    border: solid 1px #f00 !important;
  }
  label.err {
    background-color: #fffafa !important;
    border: solid 1px #f00 !important;
  }
  .bold-text {
    text-align: center;
    font-size: 18px;
    font-weight: 700;
  }
  .disabled {
    background-color: #c4c4c4;
  }
  .border-bottom {
    border-bottom: 1px solid #d9d9d9;
  }
  .text-center {
    text-align: center;
  }
</style>
<style lang="scss">
  .remodal-wrapper:after {
    vertical-align: middle;
  }
  .fontB {
    font-weight: 700!important;
  }
  p.kome {
    margin: 0 !important;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
    font-style: normal;
    font-weight: normal;
    text-align: left;
    text-indent: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
</style>

<style lang="scss">
  #main #entry-form p.form-privacy {
    text-align: center;
    // font-size: 0.9em;
    // font-weight: 700;
    padding-top: 2rem;
  }
  .date-err {
    border: solid 1px #f00 !important;
  }
  .skip-txt {
    display: inline-block;
    position: relative;
  }
</style>

<style scoped lang="scss" src="@/assets/scss/style.scss"></style>
