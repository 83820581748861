import { render, staticRenderFns } from "./Register.vue?vue&type=template&id=3c6044e3&scoped=true&"
import script from "./Register.vue?vue&type=script&lang=js&"
export * from "./Register.vue?vue&type=script&lang=js&"
import style0 from "./Register.vue?vue&type=style&index=0&id=3c6044e3&prod&scoped=true&lang=scss&"
import style1 from "./Register.vue?vue&type=style&index=1&id=3c6044e3&prod&lang=scss&"
import style2 from "./Register.vue?vue&type=style&index=2&id=3c6044e3&prod&lang=scss&"
import style3 from "@/assets/scss/style.scss?vue&type=style&index=3&id=3c6044e3&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c6044e3",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
installComponents(component, {VCheckbox})
