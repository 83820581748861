<template>
    <v-dialog v-model="withdrawMemberComfirmDialog" persistent style="z-index=1001;" width="460px">
      <v-card style="border-radius: 1rem;">
        <v-card-title class="border-bottom">
          <div>{{$t("CHANGE_INFO_WITHDRAW_MEMBER_COMFIRM_DIALOG_HEADER_TEXT")}}</div>
          <v-spacer></v-spacer>
          <v-btn
            icon
            dark
            @click.stop="closeWithdrawDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>

        </v-card-title>
        <v-card-text class="border-bottom mb-5">
          <div v-if="errors===null || errors.length === 0">
            <p class="text-center">{{$t("CHANGE_INFO_WITHDRAW_MEMBER_COMFIRM_DIALOG_MESSAGE_TEXT")}}</p>
          </div>
          <div class="modal_content" v-else>
            <p>
                <label v-for="(err, index) in errors" :key="index" :class="err.success ? '' : 'txt-err'">{{err.errorMessage}}</label>
            </p>
            <div class="modal_btn">
              <button
                type="button"
                id="close_btn"
                class="btn modal_comfirm_btn"
                @click="closeWithdrawDialog"
              >
                {{$t('CHANGE_INFO_WITHDRAW_MEMBER_COMFIRM_DIALOG_CANCLE_LABEL')}}
              </button>
            </div>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="closeWithdrawDialog" color="red" class="white--text">{{$t("CHANGE_INFO_WITHDRAW_MEMBER_COMFIRM_DIALOG_CANCLE_LABEL")}}</v-btn>
          <v-btn  v-if="errors===null || errors.length === 0" @click="withdrawMember" color="#364A81" class="white--text">{{$t("CHANGE_INFO_WITHDRAW_MEMBER_COMFIRM_DIALOG_OK_LABEL")}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
export default {
  props : {
    withdrawMemberComfirmDialog : {
      type    : Boolean,
      default : false
    },
    withdrawMember : {
      type    : Function,
      default : Object
    },
    closeWithdrawDialog : {
      type    : Function,
      default : Object
    },
    errors : {
      type    : Array,
      default : null
    },
    withdrawSuccess : {
      type    : Boolean,
      default : false
    },
  },
  mounted() {
  },
  methods : {
  }
}
</script>
<style scoped lang="scss">
    .v-application p {
        margin-bottom: inherit !important;
    }
    .modal_body {
        border: none !important;
    }
    .v-dialog__content {
        justify-content: center;
        align-items: center;
    }
    .v-dialog > .v-card > .v-card__text {
        padding: 0 !important;
    }
    .v-card > .v-card__text {
        color: #333 !important;
    }
    .container {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    .modal_comfirm_btn:disabled {
        background-color: #94A5B2;
        color: #fff;
        cursor: not-allowed;
    }
    .txt-err {
      color: #f00;
      cursor: text;
    }
    .login-required-txt {
      color: #f00;
      cursor: text;
    }
    .modal_comfirm_btn:not(disabled){
      color: #fff;
      background-color: #0862A5;
    }
    .no_underline {
      cursor: pointer !important;
      text-decoration: none !important;
    }
    @media screen and (min-width: 769px) {
      .success_message_small {
        font-size: 1.2em !important;
      }
    }
    .modal_comfirm_btn {
      margin-left: 10px !important;
      margin-right: 10px !important;
      text-align: center;
    }
    .v-btn > .v-btn__content .v-icon {
      color: #000;
    }
</style>
<style scoped lang="scss" src="@/assets/scss/style.scss"></style>
