import {defineStore} from 'pinia'
import Methods from '../api/methods'

const useSetting = defineStore('useSetting', {
  state : () => {
    return {
      // 入札確認画面非表示の有無
      skipBidConfirm : false,
      hasCard        : false,
    }
  },
  actions : {
    setSkipBidConfirm(val) {
      this.skipBidConfirm = val
    },
    setHasCard(val) {
      this.hasCard = val
    },
    updateSetting(locale = 'ja') {
      const params = {
        skipBidConfirm  : this.skipBidConfirm
      }
      return Methods.apiExcute('private/update-user-setting', locale, params)
        .then(data => {
          console.log('data:', data)
          return Promise.resolve()
        })
    }
  },
  getters : {
    isSkipBidConfirm : state => {
      return !!state.skipBidConfirm
    }
  },
})

export default useSetting
